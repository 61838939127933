<template>
<!-- 非寄售弹框 -->
  <div class="tableList">
    <div class="fplistsearch" v-if="haveSearct!==false">
      <div class="text">编号搜索：</div>
        <el-input @keyup.enter="fpSearch" v-model="searchinputdata" class="searchinput"></el-input>
        <el-button @click="fpSearch" class="searchbutton" round icon="el-icon-search">查询</el-button>
        <span class="hongzi">说明:无差异,点击确认;有差异,修改修正含税金额后,点击确认</span>
    </div>
     <div class="tableList">
      <div class="table">
        <el-table ref="table" :data="tableData" stripe style="width: 100%">
          <el-table-column :show-overflow-tooltip="true" :key="JSON.stringify(item)" v-for="(item) in tableField" :prop="item.code" :label="item.label" :width="item.width">
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="修正含税金额" width="160px">
            <template #default="scope">
              <span v-if="scope.row.systemStatementMark=='X'">{{scope.row.revisedAmountIncludingTax}}</span>
              <span  v-if="scope.row.systemStatementMark!='X'">
                <el-input :ref="'changeInput'+scope.$index" @blur='changeAmount(scope)' v-model="scope.row.revisedAmountIncludingTax"></el-input>
              </span>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="差异金额">
            <template #default="scope">
              <span>{{parseFloat(parseFloat(scope.row.revisedAmountIncludingTax)-parseFloat(scope.row.amountIncludingTax)).toFixed(2)}}</span>
            </template>
          </el-table-column>
      </el-table>
      </div>
    </div>
    <div class="describe">
      <span class="titit">对账数量:{{inProgressQuantityTotal}}</span>
      <span class="titit">对账单含税金额:{{amountIncludingTaxTotal}}</span>
      <span class="titit">修正含税金额:{{revisedAmountIncludingTotal}}</span>
      <span class="titit">差异:{{differenceTotal}}</span>
      <span class="titit">差异原因:<el-input style="margin-left: 5px;width: calc( 100% - 760px);min-width: 200px;" v-model="formDataCopy.differenceReason"></el-input></span>
    </div>
    <dialog-box dialogWidth="1200px" :formData="searchedData" :dialogShow="dialogShow" @handleClose='handleClose'  title="查询结果"
    componentName="SupStatementDetailDialogN" :haveSearct="false"
            :buttonData="[{ label: '确认', action: 'handleClose', size: 'mini', type:'primary' }]">
    </dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { tableField } from './supStatementDetailDialogN'
import { request } from '@/assets/js/http.js'
export default {
  name: 'SupStatementDetailDialogN',
  components: Component.components,
  props: ['formData', 'haveSearct'],
  data () {
    return {
      tableField: tableField,
      tableData: this.formData.reconciliations,
      inProgressQuantityTotal: 0,
      amountIncludingTaxTotal: 0,
      formDataCopy: this.formData,
      differenceTotal: 0,
      revisedAmountIncludingTotal: 0,
      searchinputdata: '',
      searchedData: { reconciliations: [] },
      dialogShow: false
    }
  },
  created () {
    for (const item of this.tableData) {
      if (!item.revisedAmountIncludingTax || item.revisedAmountIncludingTax === 0) {
        item.revisedAmountIncludingTax = item.amountIncludingTax
      }
      this.inProgressQuantityTotal = this.inProgressQuantityTotal + parseFloat(item.reconciledQuantity)
      this.amountIncludingTaxTotal = (this.amountIncludingTaxTotal + parseFloat(item.amountIncludingTax))
      this.revisedAmountIncludingTotal = (this.revisedAmountIncludingTotal + parseFloat(item.revisedAmountIncludingTax))
    }
    this.revisedAmountIncludingTotal = parseFloat(this.revisedAmountIncludingTotal).toFixed(2)
    this.differenceTotal = this.revisedAmountIncludingTotal - this.amountIncludingTaxTotal
    this.amountIncludingTaxTotal = this.amountIncludingTaxTotal.toFixed(2)
    this.differenceTotal = this.differenceTotal.toFixed(2)
  },
  watch: {
    tableData: {
      handler: function (data) {
        this.revisedAmountIncludingTotal = 0
        for (const item of this.tableData) {
          this.revisedAmountIncludingTotal = (this.revisedAmountIncludingTotal + parseFloat(item.revisedAmountIncludingTax))
        }
        this.revisedAmountIncludingTotal = parseFloat(this.revisedAmountIncludingTotal).toFixed(2)
        this.differenceTotal = this.revisedAmountIncludingTotal - this.amountIncludingTaxTotal
        this.differenceTotal = this.differenceTotal.toFixed(2)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleClose () {
      this.dialogShow = false
    },
    fpSearch () {
      this.searchedData.reconciliations = []
      for (const item of this.tableData) {
        if (item.purchaseOrderNumber.includes(this.searchinputdata) || item.itemNumber.includes(this.searchinputdata)) {
          this.searchedData.reconciliations.push(item)
        }
      }
      this.dialogShow = true
    },
    changeAmount (data) {
      if (data.row.reconciledQuantity > 0 && parseFloat(data.row.revisedAmountIncludingTax) < 0) {
        this.$message({
          message: '对账数量大于零修正含税金额不可为负数！',
          type: 'error'
        })
        this.$refs['changeInput' + data.$index].focus()
      } else if (data.row.reconciledQuantity < 0 && parseFloat(data.row.revisedAmountIncludingTax) > 0) {
        this.$message({
          message: '对账数量小于零修正含税金额不可为正数！',
          type: 'error'
        })
        this.$refs['changeInput' + data.$index].focus()
      } else {
        var submitData = {}
        submitData.dzdId = this.$route.query.id
        submitData.infos = []
        for (const item of this.formData.reconciliations) {
          submitData.infos.push({ id: item.id, revisedAmountIncludingTax: item.revisedAmountIncludingTax })
        }
        request('/api/reconciliation/reconciliationInfo/feedbackDifferencesRedis', 'post', submitData).then((res) => {
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.tableList{
padding: 0px;
  .fplistsearch{
    display: flex;
    width: 100%;
    height: 30px;
    padding: 10px;
    padding-top: 0;
    .text{
      width: 72px;
      line-height: 30px;
    }
    .searchinput{
      width: 300px;
    }
    :deep(.el-input){
      height: 30px;
      line-height: 30px;
      *{
        height: 30px;
        line-height: 30px;
      }
    }
    :deep(.searchbutton){
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      min-height:30px;
      padding: 0px 16px;
      *{
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
.code{
  margin-bottom: 20px;
}
.describe{
  margin-top: 20px;
  color:#FF6633 ;
}
.hongzi{
  margin-top: 6px;
  color: #FF6633;
  margin-left: 20px;
}
.titit{
  margin-right:30px;
  font-weight: 700;
  color: #000;
}

</style>
