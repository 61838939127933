
export var tableField = [
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '' },
  { label: '采购单行号', code: 'purchaseLineNumber', type: 'input', width: '' },
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '' },
  { label: '物料描述', code: 'materialDescription', type: 'input', width: '300px' },
  { label: '对账数量', code: 'reconciledQuantity', type: 'input', width: '' },
  { label: '单价', code: 'unitPrice', type: 'amount', align: 'right', width: '' },
  { label: '本次含税金额', code: 'amountIncludingTax', type: 'amount', align: 'right', width: '' },
  { label: '税率', code: 'taxRate', type: 'input', width: '80px' }
]
export var tableData = []
